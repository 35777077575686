// custom_app/custom_app/public/js/frontend_expressions.js

let is_setting_value = false;
let is_setting_value_child = false;

frappe.call({
    method: "nxerp.utils.validate_calc_personalizado.get_doctypes_ignoring_permissions",
    callback: function(response) {
        const doctypes = response.message;
        for (const doc of doctypes) {
            frappe.ui.form.on(doc.name, {
                refresh(frm) {                   
                    addGenericFieldListeners(frm);
                    addChildTableFieldListeners(frm);
                }
            });
        }
    }
});

function desabilitarTeclado(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}

function addGenericFieldListeners(frm) {                  
    // Itera sobre todos os campos do formulário
    $.each(frm.fields_dict, function(fieldname, field) {
        let df = field.df;
        if (df.real_time_update) {
            if(df.fieldtype != "Data"){ 

                // 1. Limpa os handlers já registrados para evitar duplicidade
                if (
                    frappe.ui.form.handlers[frm.doctype] &&
                    frappe.ui.form.handlers[frm.doctype][df.fieldname]
                ) {
                    // Zera o array de funções previamente associadas ao campo
                    frappe.ui.form.handlers[frm.doctype][df.fieldname] = [];
                }

                // 2. Registra um novo handler para o campo

                frappe.ui.form.on(frm.doctype, {
                    [df.fieldname] : async function (frm) {  
                        if (frm.is_processing) return;

                        frm.is_processing = true;
                        try {                        
                            console.time('Tempo de Execução Pai');
                            document.addEventListener('keydown', desabilitarTeclado, true);
                            document.addEventListener('keypress', desabilitarTeclado, true);
                            document.addEventListener('keyup', desabilitarTeclado, true);

                            console.log(`Disparo do Onchange Pai. Campo ${df.fieldname} atualizado.`);         
                            if (df.fetch_from) {    
                                await new Promise(resolve => setTimeout(resolve, 25));
                            }
                            await evaluateFrontendExpressionsDocPai(frm, df);

                        } catch (error) {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                        } finally {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                            console.timeEnd('Tempo de Execução Pai');
                        }
                    }
                })
            }
            else {
                let $input = frm.fields_dict[fieldname].$input;

                if ($input) {
                    // Remove handlers existentes para evitar duplicatas
                    //COMENTADO pois inicialmente não foi verificada a necessidade de remover changes, visto que o on_change quando colocado no pai roda depois.
                    $input.off('change');

                    $input.on('change', async function() {  
                        if (frm.is_processing) return;

                        frm.is_processing = true;
                        try {                        
                            console.time('Tempo de Execução Pai');
                            document.addEventListener('keydown', desabilitarTeclado, true);
                            document.addEventListener('keypress', desabilitarTeclado, true);
                            document.addEventListener('keyup', desabilitarTeclado, true);

                            console.log(`Disparo do Onchange Pai. Campo ${df.fieldname} atualizado.`);         
                            
                            if (df.fetch_from) {    
                                await new Promise(resolve => setTimeout(resolve, 25));
                            }

                            //ATUALIZAR O VALOR DO CAMPO
                            //O Evento pode vir trazendo a informação anterior no frm, para garantir, sempre atualiza
                            let newValue =  $(this).val();

                            let nome =  $(this).attr('data-fieldname'); 

                            if (!frm.doc.hasOwnProperty(nome)) {
                                frm.doc[nome] = null;
                            }

                            frm.set_value(nome, newValue);
                            frm.doc[nome] = newValue;  
                            //TERMINAR DE ATUALIZAR O VALOR DO CAMPO
                            //O Evento pode vir trazendo a informação anterior no frm, para garantir, sempre atualiza 

                            await evaluateFrontendExpressionsDocPai(frm, df);

                        } catch (error) {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                        } finally {
                            frm.is_processing = false;
                            document.removeEventListener('keydown', desabilitarTeclado, true);
                            document.removeEventListener('keypress', desabilitarTeclado, true);
                            document.removeEventListener('keyup', desabilitarTeclado, true);
                            console.timeEnd('Tempo de Execução Pai');
                        }                    
                    }) 
                }     
            }
        }
    });
}

function addChildTableFieldListeners(frm) {
    // debugger
    // Itera sobre todos os campos do formulário
    $.each(frm.fields_dict, function (fieldname, field) {
        // Verifica se o campo é uma tabela filha
        if (field.df.fieldtype === 'Table') {
            const grid = field.grid;
            // Garante que a tabela filha está corretamente configurada
            if (grid) {
                // Itera sobre os campos da tabela filha
                const child_doctype = field.df.options; 
                
                frappe.meta.get_docfields(field.df.options).forEach(childField => {
                    // Verifica se o campo possui o atributo 'real_time_update' para modificar o evento onchange
                     if (childField.real_time_update ) {
                        
                        // 1. Limpa os handlers já registrados para evitar duplicidade
                        if (
                            frappe.ui.form.handlers[child_doctype] && 
                            frappe.ui.form.handlers[child_doctype][childField.fieldname]
                        ) {
                            // Zera o array de funções para esse campo no child_doctype
                            frappe.ui.form.handlers[child_doctype][childField.fieldname] = [];
                        }

                        // 2. Registra um novo handler para o campo
                           
                        frappe.ui.form.on(grid.doctype, {
                            [childField.fieldname]: async function(frm, cdt, cdn) {
                                // debugger
                                if (frm.is_processing) return;

                                frm.is_processing = true;
                                try {                        
                                    console.time('Tempo de Execução Filho');
                                    document.addEventListener('keydown', desabilitarTeclado, true);
                                    document.addEventListener('keypress', desabilitarTeclado, true);
                                    document.addEventListener('keyup', desabilitarTeclado, true);              
                                    // debugger
                                    if (childField.fetch_from) {
                                        await new Promise(resolve => setTimeout(resolve, 25));                                        
                                    }
                                    // Guarda a informação do doc do filho e do nome do campo que foi alterado
                                    const rowDoc= locals[cdt][cdn];
                                    
                                    //Chama as rotinas que irão chamar a API e autalizar o front. 
                                    //Tem tratativa para esperar a resposta assíncrona antes de continuar, para evitar rodar eventos simultâneos.    
                                    console.log(`Disparo do Onchange Filho. Campo ${childField.fieldname} atualizado na linha ${rowDoc.idx}`);
                                    await evaluateFrontendExpressionsDocFilho(rowDoc, frm, childField); 
                                    // await evaluateFrontendExpressionsDocPai(frm);  
                                } catch (error) {
                                    frm.is_processing = false;
                                    document.removeEventListener('keydown', desabilitarTeclado, true);
                                    document.removeEventListener('keypress', desabilitarTeclado, true);
                                    document.removeEventListener('keyup', desabilitarTeclado, true);
                                } finally {
                                    frm.is_processing = false;
                                    document.removeEventListener('keydown', desabilitarTeclado, true);
                                    document.removeEventListener('keypress', desabilitarTeclado, true);
                                    document.removeEventListener('keyup', desabilitarTeclado, true);
                                    console.timeEnd('Tempo de Execução Filho');
                                }                                
                            }
                        });  
                    }            
                });

                //Adiciona evento da inserçãode uma linha 
                frappe.ui.form.on(grid.doctype, {
                    [grid.df.fieldname + '_add']: async function(frm) {
                        await new Promise(resolve => setTimeout(resolve, 25));   
                        console.log(`Disparo do Onchange Adição de linha no Filho.`)
                        await evaluateFrontendExpressionsDocPai(frm); 
                    }
                });

                //Adiciona evento da remoção de uma linha 
                frappe.ui.form.on(grid.doctype, {
                    [grid.df.fieldname + '_remove']: async function(frm) {
                        await new Promise(resolve => setTimeout(resolve, 25));   
                        console.log(`Disparo do Onchange Remoção de linha no Filho.`)
                        await evaluateFrontendExpressionsDocPai(frm); 
                    }
                });
            }
        }
    });
}

async function evaluateFrontendExpressionsDocPai(frm, field_onchange = null) {
    // debugger
    try {
        console.time('Tempo de Execução Backend Pai');
        // Chama a API do backend e aguarda a conclusão
        const r = await frappe.call({
            method: "nxerp.utils.validate_calc_personalizado.evaluate_expression",
            args: {
                doc: frm.doc,             
                field_onchange: field_onchange
            }
        });
        console.timeEnd('Tempo de Execução Backend Pai');
        if (r.message) {
            if (r.message.error) {
                frappe.msgprint(`Erro na expressão personalizada: ${r.message.error}`);
            } else {
                // Evita loops infinitos de set_value
                if (!is_setting_value) {
                    is_setting_value = true;
                    let focusedElement = document.activeElement;

                    // Atualiza campos dos filhos (se houver)
                    let updated_child_fields = r.message.camposFilhosAtualizados || [];
                    for (let i = 0; i < updated_child_fields.length; i++) {
                        let update = updated_child_fields[i];
                        // Localiza o child_doc correspondente
                        
                        // Esta desta forma pois este método não chamam o on_change novamente
                        let child_doc = frm.doc[update.parentfield][update.idx - 1];
                        child_doc[update.fieldname] = update.novo_valor;
                        
                        let grid_row = cur_frm.fields_dict[update.parentfield].grid.grid_rows_by_docname[child_doc.name];
                        grid_row.refresh_field(update.fieldname);       
                                                
                        console.log(`Onchange Pai - Atualização DocFilho ${update.parentfield}, linha: ${update.idx} . Campo ${update.fieldname} atualizado com o resultado: ${update.novo_valor}`);
                    }

                    // Atualiza campos do Doc Principal (se houver)
                    let updated_doc_fields = r.message.camposDocPrincipalAtualizados || [];
                    for (let i = 0; i < updated_doc_fields.length; i++) {
                        let update = updated_doc_fields[i];

                        // Esta desta forma pois este método não chamam o on_change novamente
                        //tem este if por conta dos set em quick entry
                        if (frm && typeof frm.refresh_field === "function") {
                            frm.doc[update.fieldname] = update.novo_valor;
                            frm.refresh_field(update.fieldname)
                        }
                        else {
                            // o false é para não chamar o on_change do campo, porém continuou chamando 
                            // e está somente no caso de quick entry, onde o metodo anterior não funciona, pois nao tem como dar refresh
                            frm.set_value(update.fieldname, update.novo_valor, false);
                        }

                        

                        if (field_onchange == null){
                            console.log(`Onchange Pai - Atualização DocPai Devido a Adição ou remoção de filho. Campo ${update.fieldname} atualizado com o resultado: ${update.novo_valor}`);
                        } else {
                            console.log(`Onchange Pai - Atualização DocPai. Campo ${update.fieldname} atualizado com o resultado: ${update.novo_valor}`);
                        }
                    }
                    
                    // Voltar o foco para o elemento inicial e garante que tenha para poder focar
                    // Quando apagar linha chama a função e o campo nao vai ter para focar
                    if (focusedElement && typeof focusedElement.select === "function") {
                        focusedElement.focus();
                        focusedElement.select();
                    } else if (focusedElement && typeof focusedElement.focus === "function") {
                        // Se não for um input ou não tiver o método select, ao menos chama foco
                        focusedElement.focus();
                    } 

                    is_setting_value = false;
                }
            }
        }
    } catch (err) {
        // frappe.msgprint(`Erro na chamada do backend para ${field_onchange.label}: ${err.message}`);
        frappe.msgprint(`Erro na chamada do backend para : ${err.message}`);
        // console.error(`Erro na chamada do backend:`, err);
        is_setting_value = false;
    }
}

async function evaluateFrontendExpressionsDocFilho(rowDoc, frm, field_onchange) {
    try {
        console.time('Tempo de Execução Backend Filho');
        // Chama a API do backend e aguarda a conclusão
        const r = await frappe.call({
            method: "nxerp.utils.validate_calc_personalizado.evaluate_expression",
            args: {
                doc: rowDoc,
                docPai: frm.doc,
                field_onchange: field_onchange
            }
        });
        console.timeEnd('Tempo de Execução Backend Filho');
        if (r.message) {
            if (r.message.error) {
                frappe.msgprint(`Erro na expressão personalizada em ${field.label}: ${r.message.error}`);
            } else {
                if (!is_setting_value) {
                    is_setting_value = true;
                    let focusedElement = document.activeElement;

                    //Atualiza o DocFilho
                    let updated_child_fields = r.message.camposDocPrincipalAtualizados || [];
                    const grid_row = frm.fields_dict[rowDoc.parentfield].grid.get_row(rowDoc.name);
                    
                    for (let i = 0; i < updated_child_fields.length; i++) {
                        let update = updated_child_fields[i];
                    
                        // Atualiza o valor do campo no objeto
                        rowDoc[update.fieldname] = update.novo_valor;                
                        // Atualiza o campo na UI
                        grid_row.refresh_field(update.fieldname);

                        console.log(`Onchange Filho - Atualização DocFilho ${rowDoc.parentfield}, linha: ${rowDoc.idx} . Campo ${update.fieldname} atualizado com o resultado: ${update.novo_valor}`);
                    }
                    

                    // Atualiza campos do Doc Pai (se houver ALteração)
                    let updated_doc_fields = r.message.camposDocPaiAtualizados || [];
                    for (let i = 0; i < updated_doc_fields.length; i++) {
                        let update = updated_doc_fields[i];
                        
                        // Esta desta forma pois este método não chamam o on_change novamente
                        frm.doc[update.fieldname] = update.novo_valor;
                        frm.refresh_field(update.fieldname)

                        console.log(`Onchange Filho - Atualização DocPai. Campo ${update.fieldname} atualizado com o resultado: ${update.novo_valor}`);
                    } 
                    
                    // Voltar o foco para o elemento inicial e garante que tenha para poder focar
                    // Quando apagar linha chama a função e o campo nao vai ter para focar
                    if (focusedElement && typeof focusedElement.select === "function") {
                        focusedElement.focus();
                        focusedElement.select();
                    } else if (focusedElement && typeof focusedElement.focus === "function") {
                        // Se não for um input ou não tiver o método select, ao menos chama foco
                        focusedElement.focus();
                    } 

                    is_setting_value = false;                    
                }
            }
        }
    } catch (err) {
        is_setting_value = false;        
        frappe.msgprint(`Erro na chamada do backend (Inicio) para : ${err.message}`);      
    }
}
